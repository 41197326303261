import FormInput from "../../../helpers/form";

export function AddressFormUser({ user }) {
    const userForm = [
        { id: 'address_user', label: 'Your Address' },
        { id: 'city_user', label: 'Your City' },
        { id: 'zipcode_user', label: 'Your Zipcode' },
        { id: 'bathrooms_user', label: 'Bathrooms' },
        { id: 'bedrooms_user', label: 'Bedrooms' },
    ];
    return (
        <>
            <FormInput inputs={userForm} forms={{ id: 'form_user', style: { display: user.style.display } }} />
        </>
    )
}

export function AddressFormReferral({ referral }) {
    const referralForm = [
        { id: 'address_referral' + referral.id, label: 'Referral Address' },
        { id: 'city_referral' + referral.id, label: 'Referral City' },
        { id: 'zipcode_referral' + referral.id, label: 'Referral Zipcode' },
        { id: 'bathrooms_referral' + referral.id, label: 'Referral Bathrooms' },
        { id: 'bedrooms_referral' + referral.id, label: 'Referral Bedrooms' },
    ];

    return (
        <>
            <FormInput inputs={referralForm} forms={{ id: referral.id, style: { display: referral.style.display } }} />
        </>
    )
}