import { uniqKey } from "../helpers/key";

export default function BsTable(props) {
    return (
<div className={`table-responsive p-2 mb-3`} style={props.ml ? {marginLeft: '7%'} : {}}>            <table className={`table ${props.border===false?'table-borderless':'table-striped table-hover'}`}>
                <thead className={`${!props.bgNone?'tableHead':''}`}>
                    <tr align="center">
                        {props.columns.map((val, key) => {
                            return (
                                <th className="defaultTextColor" key={key} scope="col">{val}</th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {props.data && props.data.length
                        ? props.data.map((row, key) => {
                            return (
                                <tr key={key} align="center">
                                    {
                                        Object.entries(row).map((td, i) => {
                                            return <td key={uniqKey(i)}>{td[1]}</td>
                                        })
                                    }
                                </tr>
                            )
                        })
                        : <BsTableEmpty defaultRow={props.defaultRow?props.defaultRow:""}/>}
                </tbody>
            </table>
        </div>
    );
}
export function BsTableEmpty1(props) {
    return (
        <tr>
            <td colSpan={props.colSpan ? props.colSpan : 4} align="center">No Data Found</td>
        </tr>
    )
}

export function BsTableEmpty(props) {
    if (props.defaultRow) {
        const rows = Array.from({ length: 10 }, (_, index) => index);
        
        return (
            <>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {Array.from({ length: 4 }, (_, colIndex) => (
                            <td key={colIndex}>&nbsp;</td> 
                        ))}
                    </tr>
                ))}
            </>
        );
    } else {
        return (
            <tr>
                <td colSpan={props.colSpan ? props.colSpan : 4} align="center">No Data Found</td>
            </tr>
        );
    }
}
