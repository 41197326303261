import { Link } from "react-router-dom";
import { isLogedIn } from "../../helpers/loginCheck";
export default function Navbar({ path }) {
    return (
        <nav className="main-navbar navbar bg-body-tertiary px-2">
            <ul className="nav nav-underline">
                <li className="nav-item">
                    <Link className={"headerLinks nav-link" + (path == '/' ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} aria-current="page" to="/">Home</Link>
                </li>
                {isLogedIn() ?
                    <li className="nav-item">
                        <Link className={"headerLinks nav-link" + (path == '/u/leaderboard' ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} aria-current="page" to="/u/leaderboard">My Leaderboard</Link>
                    </li>
                    : ''}
            </ul>
            <ul className="nav nav-underline justify-content-end">
                <li className="nav-item">
                    <Link className={"headerLinks nav-link" + (path.includes('/referral') ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} to="/referral" style={{ pointerEvents: path.includes('/referral') ? 'none' : '' }}>Referral</Link>
                </li>
                {isLogedIn() ?
                    <li className="nav-item">
                        <Link className="nav-link" to='/logout'>Logout</Link>
                    </li>
                    :
                    <li className="nav-item">
                        <Link className="nav-link" to='/login'>Login</Link>
                    </li>
                }
            </ul>
        </nav>
    );
}

function setActiveClass(e) {
    document.querySelectorAll('.headerLinks')
        .forEach(element => {
            element.classList.remove('active');
        })
    e.currentTarget.classList.add('active');
}