import { getCounties, getMsas, getStates, getZipByMsa } from "../../../api/commonApi";
import { getRankData } from "../../../api/rankcollegeApi";
import FormInput from "../../../helpers/form";
import React, { useState, useEffect, useRef } from "react";
import { val } from "../../../helpers/val";
import { AddressFormReferral } from "./addressForm";

export default function ReferralForm(props) {
    const [states, setStates] = useState([]);
    const [msas, setMsas] = useState([]);
    const [counties, setCounties] = useState([]);
    const [zipcodes, setZipcodes] = useState('');
    const [addressFormDisplay, setAddressFormDisplay] = useState('none');

    const [favCollege, setFavCollege] = useState(['{EmptySelect:true}|']);
    const [referralCollectives, setReferralCollectives] = useState(['{EmptySelect:true}|']);
    const ReferralPropertyTypeRef = useRef();

    useEffect(() => {
        getRankData('favCollege').then(res => {
            setFavCollege(['{EmptySelect:true}|', ...res.data]);
        })
    }, []);

    const getCollectives = (e, v) => {
        getRankData('collectives', v).then(res => {
            setReferralCollectives(['{EmptySelect:true}|', ...res.data]);
        })
    }

    useEffect(() => {
        getStates().then((res) => {
            setStates(manageStates(res));
            getCountiesByState('FL');
        }).catch((err => {
            console.warn(err)
        }))
        ReferralPropertyTypeRef.current.parentNode.style.display = 'none';
    }, []);

    const getCountiesByState = (e) => {
        getCounties('', typeof e === 'string' ? e : e.currentTarget.value).then(res => {
            setCounties(res.counties);
        });
        getMsas(typeof e === 'string' ? e : e.currentTarget.value, 'abb').then(res => {
            setMsas(manageMsas(res));
        });
    }
    const setZipcodeByMsa = (e) => {
        getZipByMsa(val('#ReferralState' + props.id), 'abb', e.currentTarget.value)
            .then((res) => {
                val('#ReferralZipCode' + props.id, res.zipcode);
            });
    }
    const toggleAdressForm = (e) => {
        const val = e.currentTarget.value;
        if (['Landlord', 'selling'].includes(val)) {
            setAddressFormDisplay('');
        } else {
            setAddressFormDisplay('none');
        }
        if(val){
            ReferralPropertyTypeRef.current.parentNode.style.display = '';
        }else{
            ReferralPropertyTypeRef.current.parentNode.style.display = 'none';
        }
    }

    const propertyTypeOptions = {
        '': 'Select',
        RESI: 'RESI (Residential for Sale)',
        RLSE: 'RLSE (Residential Lease)',
        RINC: 'RINC (Residential Income)',
        COMM: 'COMM (Commercial)',
        COML: 'COML (Commercial Lease)',
        BZOP: 'BZOP (Business Oppurtunity)',
        LAND: 'LAND (Vacant/Agricultural)'
    }

    const referralForm = [
        { inputType: 'text', label: "Referral's Full Name", id: 'ReferralFullName' + props.id },
        { label: "Referral's Email", id: 'ReferralEmail' + props.id },
        { label: "Referral's Phone", id: 'ReferralPhone' + props.id },
        { inputType: 'select', label: "Relationship to Referral", id: 'RelationshipToReferral' + props.id, options: { friend: 'Friend', family: 'Family', colleague: 'Colleague' } },
        { label: "Referral's State", id: 'ReferralState' + props.id, options: states, changeEvent: getCountiesByState },
        { label: "Referral's Msa", id: 'ReferralMsa' + props.id, options: msas, changeEvent: setZipcodeByMsa },
        { label: "Referral's County", id: 'ReferralCounty' + props.id, options: counties },
        { inputType: 'textarea', label: "Referral's ZipCode", id: 'ReferralZipCode' + props.id },
        { inputType: 'select', label: "Referral's Favorite College", id: 'ReferralFavoriteCollege' + props.id, options: favCollege, changeEvent: getCollectives, suggestionBox: true },
        { label: "Referral's Collective", id: 'ReferralCollective' + props.id, options: referralCollectives },
        { inputType: 'select', label: "Real Estate Needs", id: 'RealEstateNeeds' + props.id, changeEvent: toggleAdressForm, options: { not_at_this_time: 'Not At This Time', buying: 'Buying', selling: 'Selling', refinancing: 'Refinancing', Tenant: 'Tenant', Landlord: 'Landlord' } },
        { inputType: 'select', label: "Referral's Property Type", id: 'ReferralPropertyType' + props.id, options: propertyTypeOptions, useref:ReferralPropertyTypeRef },
    ];

    return (
        <>
            <hr style={{ borderTop: 'solid 5px' }} />
            <FormInput inputs={referralForm} forms={{ id: props.id, method: 'Post', name: props.id, style: { display: '' } }} />
            <AddressFormReferral referral={{ id: 'form_referral_address' + props.id, style: { display: addressFormDisplay } }} />
        </>
    );
}

function manageStates(res) {
    const statesObject = {};
    if (res.enabled) {
        for (const key in res.enabled) {
            if (Object.hasOwnProperty.call(res.enabled, key)) {
                const state = res.enabled[key];
                statesObject[key] = state;
            }
        }
    }
    if (res.stateWait) {
        statesObject['{disabled:true}|'] = '---------Waiting List---------';
        for (const key in res.stateWait) {
            if (Object.hasOwnProperty.call(res.stateWait, key)) {
                const state = res.stateWait[key];
                statesObject[key] = state;
            }
        }
    }

    if (res.disabled) {
        for (const key in res.disabled) {
            if (Object.hasOwnProperty.call(res.disabled, key)) {
                const state = res.disabled[key];
                statesObject[`{disabled:true}|${key}`] = state;
            }
        }
    }
    return statesObject;
    // ['ReferralState', 'ReferringState'].forEach(id => {
    //     const element = document.getElementById(id);
    //     if (!element) return;

    //     element.innerHTML = '';

    //     element.value = 'FL';
    //     if (id == 'ReferringState') {
    //         setCounties('FL', 'ReferringCounty');
    //     } else {
    //         setCounties('FL');
    //     }
    // })
}

function manageMsas(res) {
    const msaObj = {};
    msaObj[''] = 'Select Msa';
    if (res.msa) {
        for (const key in res.msa) {
            if (Object.hasOwnProperty.call(res.msa, key)) {
                const msa = res.msa[key];
                msaObj[msa] = msa;
            }
        }
    }

    if (res.msaWait) {
        msaObj['{disabled:true}|'] = '---------Waiting List---------';
        for (const key in res.msaWait) {
            if (Object.hasOwnProperty.call(res.msaWait, key)) {
                const msaWait = res.msaWait[key];
                msaObj[msaWait] = msaWait;
            }
        }
    }

    return msaObj;
}