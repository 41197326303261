import { uniqKey } from "../../helpers/key";
import { BsSuggestionBox } from "../suggestionbox ";

export function BsSelect({ id, label, options, changeEvent = null, suggestionBox, value = '', useref = null }) {
    return (
        suggestionBox ?
            <BsSuggestionBox id={id} label={label} defaultSuggestions={options} changeEvent={changeEvent} value={value} />
            :
            <div className="form-floating mb-3">
                <select className="form-select" id={id} aria-label={label} onChange={(e) => { changeEvent && changeEvent(e) }} ref={useref}>
                    <GetOptions options={options} />
                </select>
                <label htmlFor={id}>{label}</label>
            </div>
    )
}

function GetOptions({ options }) {
    if (Array.isArray(options)) {
        let i = 0;
        return options.map(option => {
            return (
                isEmptySelect(option) ?
                    <option key={'_' + uniqKey(i)} value={''}>Select</option>
                    :
                    <option key={'_' + option} value={option}>{option}</option>
            )
        });
    }

    else if (typeof options === 'object') {
        let i = 0;
        return Object.entries(options).map(([value, label]) => (
            !isDisabled(value) ?
                <option key={'_' + value} value={value}>
                    {label}
                </option>
                :
                <option key={'_' + i + value.replace('{disabled:true}|', '')} disabled={true} value={value.replace('{disabled:true}|', '')}>
                    {label}
                </option>
        ))
    }
}

function isDisabled(value) {
    if (value.startsWith('{disabled:true}|')) {
        return true;
    }
    return false;
}

function isEmptySelect(value) {
    if (value.startsWith('{EmptySelect:true}|')) {
        return true;
    }
    return false;
}
