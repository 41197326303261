import { useNavigate } from "react-router-dom"
import { deleteLoginDetail } from "../helpers/loginCheck";
import { useEffect } from "react";

export default function Logout(){
    const navigate = useNavigate();
    useEffect(()=>{
        deleteLoginDetail();
        navigate('/');
    },[])
    return(
        <p>Loging out...</p>
    )
}