export const schoolCssVar = document.querySelector(':root');

export function setSchoolCssVar(key,val){
    if(!key.startsWith('--school')){
        key = `--school${key}`;
    }
    schoolCssVar.style.setProperty(key,val);
}

export function setSchoolCssVars(obj){
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            const value = obj[key];
            setSchoolCssVar(key,value);
        }
    }
}