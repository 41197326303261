import React, { useState, useEffect, useRef } from 'react';

function BsSuggestionDiv({ id, suggestions, onSelect, hoveredItem }) {

    return (
        <div className="suggestion-box overflow-auto w-100 mb-3 myList" style={{ maxHeight: '250px', position: 'absolute', zIndex: 100 }} id={id} >
            <div className="list-group rounded-0 mb-3">
                {suggestions.map((suggestion, index) => (
                    // <div key={index} className="suggestion pointer" onClick={() => onSelect(suggestion)}>
                    //     {suggestion}
                    // </div>
                    <button id={`listItem${index}`} key={index} type="button" className={`list-group-item list-group-item-action suggestion pointer round-0 ${hoveredItem === index ? 'hovered' : ''}`} aria-current="true" onClick={(e) => onSelect(suggestion,e)}>
                        {suggestion}
                    </button>
                    /* <button type="button" className="list-group-item list-group-item-action">A second button item</button>
                    <button type="button" className="list-group-item list-group-item-action">A third button item</button>
                    <button type="button" className="list-group-item list-group-item-action">A fourth button item</button>
                    <button type="button" className="list-group-item list-group-item-action" disabled>A disabled button item</button> */
                ))}
            </div>
        </div>
    );
}

export function BsSuggestionBox({ type, id, label, options, changeEvent = null, defaultSuggestions = [], value='' }) {
    const [inputValue, setInputValue] = useState('');
    const [selectedSuggestion, setSelectedSuggestion] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [hoveredItem, setHoveredItem] = useState(null);
    const listRef = useRef(null);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setShowSuggestions(value !== '');
    };

    const handleSelectSuggestion = (suggestion,e) => {
        setInputValue(suggestion);
        setSelectedSuggestion(suggestion);
        setShowSuggestions(false);
        if (typeof changeEvent === 'function') {
            changeEvent(e,suggestion);
        }
    };
    let filterSuggestions = defaultSuggestions.filter(
        suggestion =>
            inputValue ? suggestion.toLowerCase().startsWith(inputValue.toLowerCase()) : ''
    ).slice(0, 10);

    const handleKeyDown = (e) => {
        const listItems = filterSuggestions;
        if (!listItems || !listItems.length) return;
        if (e.key === 'ArrowDown') {
            setHoveredItem((prev) => (prev === null ? 0 : (prev + 1) % listItems.length));
            scrollList();
        } else if (e.key === 'ArrowUp') {
            setHoveredItem((prev) => (prev === null ? listItems.length - 1 : (prev - 1 + listItems.length) % listItems.length));
            scrollList();
        } else if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            setInputValue(listItems[hoveredItem] || '');
            setShowSuggestions(false);
            if (typeof changeEvent === 'function') {
                changeEvent(e,listItems[hoveredItem] || '');
            }
        }
    };

    const handleOnBlur = (e) => {
        setTimeout(() => {
            setShowSuggestions(false);
        }, 1000);
    }

    const scrollList = () => {
        if (listRef.current) {
            const listItem = listRef.current.parentNode.querySelector(`#listItem${hoveredItem}`);
            listItem && listItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    };

    useEffect(()=>{
        if(value){
            setInputValue(value);
        }
    },[])

    useEffect(() => {
        scrollList();
    }, [hoveredItem]);

    return (
        <div className="form-floating mb-3" style={{ position: 'relative' }}>
            <input
                type="text"
                className="form-control"
                id={id}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                placeholder={label}
                ref={listRef}
                onBlur={handleOnBlur}
            />
            {showSuggestions && <BsSuggestionDiv id={id} suggestions={filterSuggestions} onSelect={handleSelectSuggestion} hoveredItem={hoveredItem} />}
            <label htmlFor={id}>{label}</label>
        </div>
    );
}
