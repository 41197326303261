export function BsInput({ inputType, inputName, id, label, placeholder, onInput = null, className = '', row = false, inputGroup = null, required = false, useref = null }) {
    if (inputGroup) {
        return (
            <InputGroup inputGroup={inputGroup} inputElement={<_BsInput label={label} inputType={inputType} id={id} onInput={onInput} className={className} required={required} />} />
        );
    }
    return (
        inputType === 'hidden'
            ?
            <input type="hidden" id={id} name={inputName} />
            :
            <div className="form-floating mb-3">
                <input type={inputType} className={`form-control ${className}`} id={id} placeholder={placeholder ?? label} name={inputName} onInput={e => onInput && onInput(e)} ref={useref}/>
                <label htmlFor={id}>{label}</label>
            </div>
    );
}

export function Input({ inputType, inputName, id, placeholder }) {
    return (
        <input type={inputType} name={inputName} id={id} placeholder={placeholder} />
    );
}
function InputGroup(props) {
    return (
        <div className="input-group mb-3">
            <span className="input-group-text">{props.inputGroup}</span>
            {props.inputElement}
        </div>
    )
}

function _BsInput(props) {
    return (
        <div className="form-floating">
            <input type={props.inputType} className={`form-control ${props.className}`} id={props.id} placeholder={props.placeholder ?? props.label} name={props.inputName} onInput={e => props.onInput && props.onInput(e)} required={props.required} />
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    )
}