import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const CopyLink = ({ children, link }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    // Modern approach using navigator.clipboard.writeText (preferred)
    if (navigator.clipboard) {
      navigator.clipboard.writeText(link)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Set a temporary visual cue
        })
        .catch((error) => {
          console.error('Failed to copy:', error);
          // Fallback to older approach if necessary
          copyToClipboard(link);
        });
    } else {
      // Fallback to older approach for older browsers
      copyToClipboard(link);
    }
  };

  const copyToClipboard = (text) => {
    const input = document.createElement('input');
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
  };

  return (
    <button
      onClick={handleCopy}
      className={`${copied ? 'copied' : ''} btn`} // Add Bootstrap class for styling
    >
      {children || 'Copy Link'} <FontAwesomeIcon icon={faCopy} />
      {copied && <span className="copied-message text-primary"> - Copied!</span>}
    </button>
  );
};

export default CopyLink;
