import axios from "axios";
import { getToken } from "../helpers/loginCheck";

const API_KEY = 'broker3112linux117';
const API_TOKEN = getToken();
//const API_BASE_URL = 'http://broker.com/index.php/';
const API_BASE_URL = 'https://brokerlinx.com/crm/';

export async function get(path, params='',config){
    params = `apikey=${API_KEY}&token=${API_TOKEN}${params ? `&${params}` : ''}`;
    const x = await axios.get(`${API_BASE_URL}${path}?${params}`,config);
    return await x.data;
}

export async function post(path, data, config){
    const x = await axios.post(`${API_BASE_URL}${path}?apikey=${API_KEY}&token=${API_TOKEN}`, data, config);
    return await x.data;
}

