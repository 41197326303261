import { get,post } from "./api";

const API_PATH = 'commonApi/';
const STATE_API = `${API_PATH}allenablestates`;
const COUNTY_API = `${API_PATH}allcounties`;
const MSA_API = `${API_PATH}allmsa`;
const ZIP_API = `${API_PATH}allzipbymsa`;

export function getStates(){
    return new Promise((resolve, reject) => {
        get(STATE_API)
        .then((res)=>{
            resolve(res);
        })
        .catch((err)=>{
            reject(err);
        });
    });
}

export function getMsas(state, state_type){
    return new Promise((resolve, reject) => {
        get(MSA_API, `state=${state}&state_type=${state_type}`)
        .then((res)=>{
            resolve(res);
        })
        .catch((err)=>{
            console.warn(err);
        });
    });
}

export function getZipByMsa(state,state_type,msa){
    return new Promise((resolve, reject) => {
        get(ZIP_API,`state=${state}&state_type=${state_type}&msa=${msa}`)
        .then((res)=>{
            resolve(res);
        })
        .catch((err)=>{
            reject(err);
        });
    });
}

export function getCounties(state, state_abb){
    return new Promise((resolve, reject) => {
        get(COUNTY_API,`state=${state}&state_abb=${state_abb}`)
        .then((res)=>{
            resolve(res);
        })
        .catch((err)=>{
            reject(err);
        });
    });
}