import React, { useState, useEffect } from 'react';
import { getCollegeRank } from "../../api/rankcollegeApi";
import { TablePlaceholder } from '../../served-components/form/placeholder';
import Pagination from '../../served-components/pagination/pagination';
import { BsCaret } from './components/icons';
import Accordion from '../../served-components/accordion';
import { Link, useLocation } from 'react-router-dom';
import { BsTableEmpty } from '../../served-components/table';

export default function Home() {
    const [data, setData] = useState([]);
    const [dataFound, setDataFound] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [sortedColumn, setSortedColumn] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);
    const location  = useLocation();

    useEffect(() => {
        getCollegeRank(currentPage, itemsPerPage, location.pathname.startsWith('/u')).then(res => {
            setData(res.data);
            if(!res.data.length){
                setDataFound(false);
            }
            setTotalPages(res.totalPages);
        }).catch(err => console.warn(err))
    }, [currentPage, itemsPerPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    const setItem = (e) => {
        setItemsPerPage(e.currentTarget.value);
        setCurrentPage(1);
    }

    const handleSort = (key) => {
        const isAsc = sortedColumn === key && sortOrder === 'asc';
        const newSortOrder = isAsc ? 'desc' : 'asc';
        const sortedData = [...data].sort((a, b) => {
            let row1 = a[key];
            let row2 = b[key];
            if (['EfficiencyRank', 'Total$Rank', 'TotalRevenue', 'TotalRevenueYear', 'TotalExpenses', 'TotalExpensesYear', 'TotalProfit', 'APRank'].includes(key)) {
                row1 = row1 ? Number(row1.replaceAll(/[^0-9\.]/g, '')) : row1;
                row2 = row2 ? Number(row2.replaceAll(/[^0-9\.]/g, '')) : row2;
            }

            if (isAsc) {
                return row1 > row2 ? 1 : -1;
            } else {
                return row1 < row2 ? 1 : -1;
            }
        });
        setData(sortedData);

        setSortedColumn(key);
        setSortOrder(newSortOrder);
    };

    const columns = [
        'AP Rank',
        'Efficiency Rank',
        'Total $ Rank',
        'School',
        'Full School Name',
        'Team',
        'Collective',
        'Referrals',
        'NIL Raised',
        // 'Focus',
        // 'Description',
        'State',
        'Total Revenue',
        'Total Revenue Year',
        'Total Expenses',
        'Total Expenses Year',
        'Total Profit'
    ];
    return (
        <>
            <div className="table-responsive p-2 mb-3">
                <table className="table table-striped table-hover">
                    <thead className="table-dark">
                        <tr>
                            {columns.map((val, key) => {
                                return (
                                    <th className={`defaultTextColor pointer sorting asc`} onClick={() => handleSort(val.replaceAll(' ', ''))} key={key} scope="col">{val} < BsCaret dir={sortedColumn === val.replaceAll(' ', '') ? sortOrder : ''} /></th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.length
                            ? data.map((val, key) => {
                                let collectives = val.Collective;
                                const collectiveBody = [];
                                const collectiveTitle = [];
                                if (collectives) {
                                    collectives = collectives.split('|');
                                    let i = 0;
                                    collectives.forEach(collective => {
                                        if (i === 0) {
                                            collectiveTitle[i] = <span key={`accordianTitleRank${key}${i}`}>{collective}</span>;
                                        } else {
                                            collectiveBody[i] = <div key={`accordianBodyRank${key}${i}`}><span>{i + 1}: </span>{collective}</div>;
                                        }
                                        i++;
                                    });
                                }
                                return (
                                    <tr key={key}>
                                        <td>{val.APRank}</td>
                                        <td>{val.EfficiencyRank}</td>
                                        <td>{val.Total$Rank}</td>
                                        <td><Link to={`/${val.School}`}>{val.School}</Link></td>
                                        <td>{val.FullSchoolName}</td>
                                        <td>{val.Team}</td>
                                        <td>{collectiveTitle && collectiveTitle.length && collectiveBody && collectiveBody.length ? <Accordion id={`rankTable${key}`} title={collectiveTitle} body={collectiveBody} /> : val.Collective}</td>
                                        <td>{val.Referrals}</td>
                                        <td>{val.NILRaised}</td>
                                        {/* <td>{val.Focus}</td> */}
                                        {/* <td>{val.Description}</td> */}
                                        <td>{val.State}</td>
                                        <td>{val.TotalRevenue}</td>
                                        <td>{val.TotalRevenueYear}</td>
                                        <td>{val.TotalExpenses}</td>
                                        <td>{val.TotalExpensesYear}</td>
                                        <td>{val.TotalProfit}</td>
                                    </tr>
                                )
                            })
                            : dataFound===false ?<BsTableEmpty colSpan={17}/>:<TablePlaceholder tr="12" td="17" />}
                    </tbody>
                </table>
            </div>
            <Pagination totalPages={totalPages} currentPage={currentPage} itemsPerPage={itemsPerPage} onChange={setItem} onClick={handlePageChange} />
        </>
    );
}