export function isLogedIn() {
    const token = localStorage.getItem('loginagenttoken');
    if (token && token != 'null' && token != 'undefined' && typeof token === 'string') {
        return true;
    }
    return false;
}

export function getToken(){
    return localStorage.getItem('loginagenttoken');
}

export function deleteLoginDetail() {
    localStorage.clear();
}