export default function Accordion(props) {
    return (
        <div className="accordion accordion-flush custom-accordion" id="accordionFlushExample" key={`accordian${props.id}`}>
            <div className="accordion-item" key={`accordian1${props.id}`}>
                <h2 className="accordion-header" key={`accordian2${props.id}`}>
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${props.id}`} aria-expanded="false" aria-controls="flush-collapseOne" key={`accordian3${props.id}`}>
                        {props.title}
                    </button>
                </h2>
                <div id={props.id} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample" key={`accordian4${props.id}`}>
                    <div className="accordion-body" key={`accordian5${props.id}`}>{props.body}</div>
                </div>
            </div>
        </div>
    );
}