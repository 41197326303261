import { get, post } from "./api";

// API URLs
 const API_PATH = 'RankApiUpdate/';
//const API_PATH = 'RankApi/';
const GET_COLLEGE_RANK = `${API_PATH}getRanksCollege`;
const SET_REFERRAL_DATA = `${API_PATH}setData`;
const GET_RANK_DATA = `${API_PATH}getRankData`;
const GET_SCHOOL_DATA = `${API_PATH}schoolData`;

export async function getCollegeRank(currentPage, itemsPerPage, forUser) {
    forUser = forUser ? 'Y' : 'N';
    return await get(GET_COLLEGE_RANK, `page=${currentPage}&limit=${itemsPerPage}&forUser=${forUser}`);
}

export async function saveReferralForm(data) {
    return await post(SET_REFERRAL_DATA, data);
}

export function getRankData(key, fullSchoolName = '') {
    return new Promise((resolve, reject) => {
        get(GET_RANK_DATA, `key=${key}&fullSchoolName=${fullSchoolName}`).then((res => {
            resolve(res);
        })).catch((err) => {
            console.warn('GET_RANK_DATA=> ', err);
        })
    })
}

export function getSchoolData(school,forUser) {
    forUser = forUser ? 'Y' : 'N';
    return new Promise((resolve, reject) => {
        get(GET_SCHOOL_DATA,`school=${school}&forUser=${forUser}`).then((res => {
            resolve(res);
        })).catch((err) => {
            console.warn('GET_SCHOOL_DATA=> ', err);
        })
    });
}


// Login API
const ADD_PASSWORD = `${API_PATH}addPassword`;
const LOGIN = `${API_PATH}login`;
const AGENT_INFO = `${API_PATH}agentInfo`;

export function addPassword(id, data) {
    return new Promise((resolve, reject) => {
        post(`${ADD_PASSWORD}/${id}`, data).then(res => resolve(res))
            .catch(err => console.warn(err));
    })
}

export function login(data) {
    return new Promise((resolve, reject) => {
        post(LOGIN, data).then(res => resolve(res))
            .catch(err => console.warn(err));
    })
}

export async function getAgentInfo() {
    try {
        return await get(AGENT_INFO);
    } catch (error) {
        console.warn(error);
    }
}