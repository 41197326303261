import FormInput from "../../helpers/form";
import { ShieldLockFill } from "../../served-components/icons";
import logo from '../../images/blinx concept_v2.jpg';
import { getFormData } from "../../helpers/formData";
import { useNavigate, useParams } from "react-router-dom";
import { addPassword } from "../../api/rankcollegeApi";

export default function CreatePassword() {
    const params = useParams();
    const navigate = useNavigate();

    function submitLogin(e) {
        const FD = getFormData(e.currentTarget.id);
        addPassword(params.id, FD).then(res => {
            alert(res.msg);
            if (res.success == true) {
                navigate('/login');
            }
        });
    }

    return (
        <div className="bg-black">
            <div className="container p-2 d-flex flex-column justify-content-center align-items-center">
                <img src={logo} className="img-fluid" alt="logo" height={500} width={500} />
                <div className="w-100 d-flex justify-content-center">
                    <FormInput inputs={[
                        { inputType: 'password', label: 'Password', id: 'password', inputGroup: <ShieldLockFill />, required: true },
                        { inputType: 'button', buttonName: 'Add Password', btnClass: 'primary rounded-pill', id: 'addPassword', fullWidth: true },
                    ]} forms={{ onSubmit: submitLogin, id: 'loginForm' }} />
                </div>
            </div>
        </div>
    );
}