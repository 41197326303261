

export function setCssVar(key,val){
    const cssVar = document.querySelector(':root');
    cssVar.style.setProperty(key,val);
}

export function setCssVars(obj){
    for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
            const value = obj[key];
            setCssVar(key,value);
        }
    }
}