export function getFormData(form, inOneKeyWithJsonStrinfy = '', ...formN) {
    const FD = new FormData();
    const DATA = {};

    [form, ...formN].forEach(f => {
        if (!f) return;
        if (!document.getElementById(f)) { return; }
        document.getElementById(f)
            .querySelectorAll('input , select, textarea')
            .forEach(element => {
                if (inOneKeyWithJsonStrinfy) {
                    DATA[element.id] = element.value;
                } else {
                    FD.append(element.id, element.value);
                }
            })
    })
    if (inOneKeyWithJsonStrinfy) {
        FD.append(inOneKeyWithJsonStrinfy, JSON.stringify(DATA));
    }
    return FD;
}