import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom"
import { getSchoolData } from "../../api/rankcollegeApi";
import logo from '../../images/schools/FloridaStateCard.jpeg';
import { CardGroup } from "../../served-components/card";
import { TextTruncation } from "../../served-components/text-truncation";
import SchoolNavbar from "../../served-components/header/schoolnav";
import FloridaState from "../../images/schools/FloridaState.png";
import BsTable from "../../served-components/table";
import Spare from "../../images/schools/spare.png";
import { setSchoolCssVars } from "../../helpers/schoolCss";
import { Link } from "react-router-dom";
import CopyLink from "../../served-components/copy-link";

export default function SchoolDetail() {
    const params = useParams();
    const [data, setData] = useState({});
    const location = useLocation();

    useEffect(() => {
        getSchoolData(params.school, location.pathname.startsWith('/u/')).then((res) => {
            const resData = res.data ? res.data : {};
            setData(resData);
        });
    }, []);
    if (params.school === 'Florida State' || data.school === 'Florida State') {
        // #f3ede1=> Gold, #782f40=> Garnet, #fff=> White
        setSchoolCssVars({
            navTextColor: '#f3ede1',
            navBgColor: '#782f40',
            conTextColor: '#782f40',
            conBgColor: '#fff',
            tableColColor: '#f3ede1',
            tableBgColColor: '#782f40',
            cardTitleColor: '#782f40',
            cardBtnBgColor: '#782f40',
            cardBtnColor: '#f3ede1',
        });
    } else {
        setSchoolCssVars({
            navTextColor: '#24c2cd',
            navBgColor: '#052c3b',
            conTextColor: '#000000',
            conBgColor: '#fff',
            tableColColor: '#24c2cd',
            tableBgColColor: '#052c3b',
            cardTitleColor: '#052c3b',
            cardBtnBgColor: '#24c2cd',
            cardBtnColor: '#000',
        });
    }


    const cards = [
        { title: data.FullSchoolName, img: { src: logo }, btn: { name: 'Read more' }, text: <TextTruncation text={data.Description} /> },
        { title: data.FullSchoolName, img: { src: logo }, btn: { name: 'Read more' }, text: <TextTruncation text={data.Description} /> },
        { title: data.FullSchoolName, img: { src: logo }, btn: { name: 'Read more' }, text: <TextTruncation text={data.Description} /> }
    ];
    const columns = ['CA User@', 'RE Referrals', 'CA Referrals', 'Total Rasied'];
    const tableData = data.leaderboard;
    const tcolumns = ['Members', 'Referrals', 'Total Raised'];
    const totalTable = data.totalTalbe ?? {};
    const ttableData = [{ Members: totalTable.Members ? totalTable.Members : 0, Referrals: totalTable.Referrals ? totalTable.Referrals : 0, TotalRaised: totalTable.TotalRaised ? totalTable.TotalRaised : 0 }];
    const schoolName = params.school ? params.school : data.School;
    return (
        <>
            <SchoolNavbar data={data} mainClass='school-detail' />
            <div className="nav w-100 justify-content-center flex-row gap-2 mt-6 " style={{ marginTop: '20px', backgroundColor: 'white', display: 'block' }}>
                <div className="d-flex flex-column align-items-center">
                    <span className="text-center reftxt" style={{ color: '#782f40' }}>Know Someone looking to Buy, Sell or Refinance?</span>
                </div>
                <div className="d-flex flex-column align-items-center">
                    <span className="text-center reftxt">
                        <Link to={schoolName ? `/${schoolName}/referral` : ''} state={{ data, caId: params.caid }} style={{ color: '#782f40' }}>
                            Refer Friends, Relatives and Co-Workers to Start Fundraising for NIL and Seminole Boosters today!
                        </Link>
                        {data.caPath ? <CopyLink link={`${window.location.origin}/${data.caPath}`}>Referral link</CopyLink> : ''}
                    </span>
                </div>
            </div>

            <div className="school-detail container py-2">
                <div className="logo d-flex justify-content-center">
                    <img src={FloridaState} alt="..." height={100} width={100} />
                </div>
                <div className="text-center">{data.School ?? ''} Referral Network</div>
                <BsTable columns={tcolumns} data={ttableData} border={false} bgNone={true} ml={true} />
                <div className="text-center mt-3"><span className="border p-1">LEADERBORAD</span></div>
                <BsTable columns={columns} data={tableData} defaultRow={true} />
                {/* <div className="card-section text-center mt-3">
                    <h1>Recruiting News</h1><hr />
                </div>
                <CardGroup key={'card_school'} cardCols={cards} />
                <div className="card-section text-center mt-3">
                    <h1>Portal News</h1><hr />
                </div>
                <CardGroup key={'card_school2'} cardCols={cards} /> */}
            </div>
        </>
    )
}