import React, { useState } from 'react';
import ReferralForm from './referralForm';
import { BsButton } from '../../../served-components/form/button';

const CounterButton = () => {
    const [count, setCount] = useState(0);
    const [appendedComponents, setAppendedComponents] = useState([]);


    const handleIncrement = () => {
        setCount(count + 1);
        const newComponents = [...appendedComponents, <ReferralForm key={count} id={'DynamicReferralForm' + count} />];
        setAppendedComponents(newComponents);
    };

    const handleDecrement = () => {
        if (count > 0) {
            setCount(count - 1);
            const newComponents = appendedComponents;
            newComponents.pop();
            setAppendedComponents(newComponents);
        }
    };

    return (
        <div>
            <div id='DynamicReferralForms' name='DynamicReferralForms' method="post">
                {appendedComponents.map((component, index) => (
                    <div key={index}>{component}</div>
                ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}><span className="counterText">{count} Referral form added</span>
                <div className="btn-group" role="group" aria-label="Basic example">
                    <BsButton buttonName='Add' btnClass='secondary float-end block' jsEvent={handleIncrement} />
                    <BsButton buttonName='Remove' btnClass='secondary float-end block' jsEvent={handleDecrement} />
                </div>
            </div>
        </div>
    );
};

export default CounterButton;
