import FormInput from "../../helpers/form";
import { ShieldLockFill, EnvelopeAt } from "../../served-components/icons";
import logo from '../../images/blinx concept_v2.jpg';
import { useNavigate } from "react-router-dom";
import { login } from "../../api/rankcollegeApi";
import { getFormData } from "../../helpers/formData";
import { useEffect } from "react";
import { isLogedIn } from "../../helpers/loginCheck";

export default function Login() {
    const navigate = useNavigate();
    useEffect(() => {
        if (isLogedIn()) {
            navigate('/');
        }
    });
    function submitLogin(e) {
        const FD = getFormData(e.currentTarget.id);
        login(FD).then(res => {
            alert(res.msg);
            if (res.success == true) {
                setLoginDetail(res.data);
                navigate('/');
            }
        });
    }

    return (
        <div className="bg-black">
            <div className="container p-2 d-flex flex-column justify-content-center align-items-center">
                <img src={logo} className="img-fluid" alt="logo" height={500} width={500} />
                <div className="w-100 d-flex justify-content-center">
                    <FormInput inputs={[
                        { inputType: 'text', label: 'Email', id: 'email', inputGroup: <EnvelopeAt />, required: false },
                        { inputType: 'password', label: 'Password', id: 'password', inputGroup: <ShieldLockFill />, required: false },
                        { inputType: 'button', buttonName: 'Login', btnClass: 'primary rounded-pill', id: 'loginBtn', fullWidth: true },
                    ]} forms={{ onSubmit: submitLogin, id: 'loginForm' }} />
                </div>
            </div>
        </div>
    );
}

function setLoginDetail(detail) {
    localStorage.setItem('login_agent_msa', detail.login_agent_msa);
    localStorage.setItem('login_agent_county', detail.login_agent_county);
    localStorage.setItem('login_agent_state', detail.login_agent_state);
    localStorage.setItem('DarkMode', detail.DarkMode);
    localStorage.setItem('loginagenttoken', detail.token)
}