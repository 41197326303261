import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Form from './pages/referral';
import Home from './pages/home/index';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import Navbar from './served-components/header/navbar';
import Login from './pages/login';
import CreatePassword from './pages/create_password';
import SchoolDetail from './pages/detail/school';
import PrivateRoute from './PrivateRoute';
import Logout from './served-components/logout';

function App() {
  return (
    <Router>
      {/* Header */}
      <NavbarWithCondition />
      <Routes>

        {/* Public Home Route */}
        <Route exact path='/' element={<Home />} />

        {/* Private Routes */}
        <Route path='u/leaderboard' element={<PrivateRoute Component={SchoolDetail} />} />

        {/* Public Routes */}
        <Route path='/referral' element={<Form />} />
        <Route path='/:school/referral' element={<Form />} />
        <Route path='/login' element={<Login />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/create_password/:id' element={<CreatePassword />} />
        <Route path='/:school/:caid?' element={<SchoolDetail />} />

        {/* Page Not Found */}
        <Route path="*" element={<h1 align="center" className='m-5 text-danger'>404 Page Not Found!</h1>} />

      </Routes>

    </Router>
  );
}

function NavbarWithCondition() {
  const location = useLocation();
  const decodedPathname = decodeURIComponent(location.pathname);
  const show = ['/', '/referral', '/login', '/create_password', '/u'].includes(decodedPathname) || decodedPathname.includes('/referral');
  const dontShow = !['/u/leaderboard'].includes(decodedPathname);

  // const shouldShowNavbar = (!/^\/[^\/]+$/gmi.test(decodedPathname) || !/^\/[^\/]+$/gmi.test(decodedPathname) || show) && dontShow;
  const shouldShowNavbar = show && dontShow;
  if (!shouldShowNavbar) {
    document.body.removeAttribute('data-bs-theme');
  } else {
    document.body.setAttribute('data-bs-theme', 'dark');
  }
  return (
    shouldShowNavbar && <Navbar path={decodedPathname} />
  );
}

export default App;
