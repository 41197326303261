export function BsButton({ buttonType, btnClass, buttonName, id, jsEvent, fullWidth }) {
    if (fullWidth) {
        return (
            <div className="d-grid gap-2 mb-3">
                <button className={'btn btn-' + btnClass} type={buttonType} id={id} onClick={(e) => { jsEvent && jsEvent(e) }} >{buttonName}</button>
            </div>
        )
    }
    else {
        return (<button type={buttonType ?? 'button'} className={'mb-3 btn btn-' + btnClass ?? 'primary'} onClick={(e) => { jsEvent && jsEvent(e) }}>{buttonName}</button>)
    }
}