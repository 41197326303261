import React, { useState, useEffect, useRef } from "react";
import { getCounties, getMsas, getStates, getZipByMsa } from "../../api/commonApi";
import { getAgentInfo, getRankData, saveReferralForm } from "../../api/rankcollegeApi";
import FormInput from "../../helpers/form";
import { AddressFormUser } from "./components/addressForm";
import CounterButton from "./components/counter";
import { getFormData } from "../../helpers/formData";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "../../helpers/theme";
import { setCssVars } from "../../helpers/cssVar";
import Spare from "../../images/schools/spare.png";


const propertyTypeOptions = {
    '': 'Select',
    RESI: 'RESI (Residential for Sale)',
    RLSE: 'RLSE (Residential Lease)',
    RINC: 'RINC (Residential Income)',
    COMM: 'COMM (Commercial)',
    COML: 'COML (Commercial Lease)',
    BZOP: 'BZOP (Business Oppurtunity)',
    LAND: 'LAND (Vacant/Agricultural)'
}


export default function Form() {
    let data = useLocation();
    data = data.state;
    const caId = data.caId;
    if (!data || !data.data) {
        data = {};
    } else {
        data = data.data;
    }

    const [yourFavoriteCollege, setYourFavoriteCollege] = useState(data.FullSchoolName ? data.FullSchoolName : '');
    const stateRef = useRef({});
    const [favCollege, setFavCollege] = useState([]);
    const [collectives, setCollectives] = useState([]);
    const [agentInfo, setAgentInfo] = useState(null);
    const navigate = useNavigate();
    // refs
    const ReferringFullNameRef = useRef('');
    const ReferringEmailRef = useRef('');
    const ReferringPhoneRef = useRef('');
    const ReferringMsaRef = useRef('');
    const ReferringCountyRef = useRef('');
    const ReferringZipCodeRef = useRef('');
    const YourRealEstateNeedsRef = useRef('');
    const ReferringPropertyTypeRef = useRef('');


    useEffect(() => {
        getRankData('favCollege').then(res => {
            setFavCollege(res.data);
        })
        if (data.Collective) {
            setCollectives(data.Collective.split('|').reverse())
        }
        if (data.FullSchoolName) {
            setYourFavoriteCollege(data.FullSchoolName);
        }

        getAgentInfo().then(res => {
            const info = res.data;
            if (res.success == true) {
                // refs
                ReferringFullNameRef.current.value = info.ListAgentFullName;
                ReferringEmailRef.current.value = info.ListAgentEmail;
                ReferringPhoneRef.current.value = info.ListAgentDirectPhone;
                ReferringMsaRef.current.value = info.MSA;
                ReferringCountyRef.current.value = info.County;
                ReferringZipCodeRef.current.value = info.ZipCodes;
                // YourRealEstateNeedsRef.current.value = 'prem';
                setYourFavoriteCollege(info.full_school_name);
                ReferringPropertyTypeRef.current.value = info.SpecializationRequested;
                setAgentInfo(info);
            }
        });
        if (typeof data === 'object' && data && Object.values(data).length && data.School == 'Florida State') {
            theme('light');
            setCssVars({
                '--bs-tertiary-bg-rgb': '120, 47, 64',
                '--bs-link-color': '#f3ede1',
                '--bs-link-hover-color': '#f3ede1',
                '--defaultTextColor': '#f3ede1',
                '--defaultBgColor': 'rgb(120, 47, 64)',
                '--inputTextColor': 'rgb(120, 47, 64)',
                '--btn-bg-color': 'rgb(120, 47, 64)',
                '--btn-text-color': '#f3ede1',
            });
        } else {
            theme('dark');
            setCssVars({
                '--bs-tertiary-bg-rgb': '248, 249, 250',
                '--bs-link-color': '#0d6efd',
                '--bs-link-hover-color': '#0a58ca',
                '--defaultTextColor': '#24c2cd',
                '--defaultBgColor': '#24c2cd',
                '--btn-bg-color': '#24c2cd',
                '--btn-text-color': '#fff',
                '--inputTextColor': '#24c2cd',
            });
        }
        handleRealEstateChange();
    }, []);

    const getCollectives = (e, v) => {
        getRankData('collectives', v).then(res => {
            setCollectives(res.data);
        })
    }
    const handleRealEstateChange = (e) => {
        if(e && e.currentTarget.value && e.currentTarget.value != 'not_at_this_time'){
            ReferringPropertyTypeRef.current.parentNode.style.display = '';
        }else{
            ReferringPropertyTypeRef.current.parentNode.style.display = 'none';
        }
    }

    const userForm = [
        { inputType: 'text', label: 'Username', id: 'Username' },
        { inputType: 'text', label: 'Your Full Name', id: 'ReferringFullName', useref: ReferringFullNameRef },
        { label: 'Your Email', id: 'ReferringEmail', useref: ReferringEmailRef },
        { label: 'Your Phone', id: 'ReferringPhone', useref: ReferringPhoneRef },
        { inputType: 'select', label: "Your State", id: 'ReferringState', changeEvent: setCounties, useref: stateRef },
        { inputType: 'select', label: "Your Sphere of Influence", id: 'ReferringMsa', changeEvent: setZipcode, useref: ReferringMsaRef },
        { label: "Your County", id: 'ReferringCounty', useref: ReferringCountyRef },
        { inputType: 'textarea', label: "Your ZipCode", id: 'ReferringZipCode', useref: ReferringZipCodeRef },
        { inputType: 'select', label: "Your Real Estate Needs", id: 'YourRealEstateNeeds', options: { not_at_this_time: 'Not At This Time', buying: 'Buying', selling: 'Selling', refinancing: 'Refinancing', Tenant: 'Tenant', Landlord: 'Landlord' }, useref: YourRealEstateNeedsRef, changeEvent:handleRealEstateChange },
        { inputType: 'select', label: 'Your Property Type', id: 'ReferringPropertyType', options: propertyTypeOptions, useref: ReferringPropertyTypeRef },
    ];

    const userFormB = [
        { inputType: 'select', label: "Your Favorite College", id: 'YourFavoriteCollege', options: favCollege, changeEvent: getCollectives, suggestionBox: true, value: yourFavoriteCollege },
        { label: "Collective", id: 'Collective', options: collectives },
        // { inputType: "select", label: "Greek Life Involvement", id: 'GreekLifeInvolvement', options: { Y: 'Yes', N: 'No' } },
        // { label: "If yes, choose chapter", id: 'Chapter', options: { alpha: 'Alpha', beta: 'Beta', gamma: 'Gamma' } },
    ];
    const button = [
        { inputType: 'button', buttonType: 'button', btnClass: ' inhouse-bg-color', buttonName: 'Submit', id: 'referralFormSubmitBtn', jsEvent: sendReferralForm, fullWidth: true }
    ];

    useEffect(() => {
        getStates().then((res) => {
            setStates(res);
            stateRef.current.value = data.State ? data.State : 'FL';
            if (data.State) {
                setCounties(stateRef.current.value);
            }
            else if (agentInfo) {
                stateRef.current.value = agentInfo.agent_state;
                setCounties(agentInfo.agent_state);
            }
        }).catch((err => {
            console.warn(err)
        }))
    }, []);

    function sendReferralForm(e) {
        const btn = e.currentTarget;
        makeBtnProssess(btn);
        const FD = getFormData('userForm', 'rankData', 'referralForm', 'form_user', 'form_referral', 'userFormB');
        mergeInnerFormsData(FD, 'DynamicReferralForms', 'referralData');
        FD.append('caId',caId);
        saveReferralForm(FD)
            .then((res) => {
                alert(res.msg);
                makeBtnProssess(btn, true);
                if (res.submitLogin && res.submitLogin.accountCreated == true && res.submitLogin.encLoginId) {
                    if (window.confirm('Your Account Added, add a strong password now?')) {
                        navigate(`/create_password/${res.submitLogin.encLoginId}`);
                    }
                }
            });
    }
    return (
        <>
            <nav className="main-navbar navbar border-bottom border-body p-4">
                <h1 className="text-center w-100 defaultTextColor">Real Estate Referral Program</h1>
            </nav>
            <div className="referral-forms container max-w-md mx-auto bg-success shadow p-3 mb-5 bg-body rounded">
                <FormInput inputs={userForm} forms={{ id: 'userForm', method: 'Post', name: 'userForm' }} />
                <AddressFormUser user={{ style: { display: 'none' } }} />
                <FormInput inputs={userFormB} forms={{ id: 'userFormB', method: 'Post', name: 'userFormB' }} />
                {/* <BsSuggestionBox defaultSuggestions={favCollege} label={'Auto'} /> */}
                {/* <FormInput inputs={referralForm} forms={{ id: 'referralForm', method: 'Post', name: 'referralForm' }} /> */}
                <CounterButton />
                {/* <ReferralForm/> */}
                {/* <AddressFormReferral referral={{ style: { display: 'none' } }} /> */}
                <FormInput inputs={button} />
            </div>
        </>
    );
}



document.addEventListener('change', (e) => {
    const val = e.target.value
    // if (e.target.id === 'RealEstateNeeds') {
    //     toggleForm('form_referral', ['Landlord', 'selling'].includes(val));
    // }
    if (e.target.id === 'YourRealEstateNeeds') {
        toggleForm('form_user', ['Landlord', 'selling'].includes(val));
    }
})

function toggleForm(id, condition) {
    if (condition) {
        document.getElementById(id).style.display = 'block';
    } else {
        document.getElementById(id).style.display = 'none';
    }
}

function setStates(res) {
    ['ReferringState'].forEach(id => {
        const element = document.getElementById(id);
        if (!element) return;

        element.innerHTML = '';
        if (res.enabled) {
            for (const key in res.enabled) {
                if (Object.hasOwnProperty.call(res.enabled, key)) {
                    const state = res.enabled[key];
                    element.innerHTML += `<option value="${key}">${state}</option>`
                }
            }
        }

        if (res.stateWait) {
            element.innerHTML += "<option disabled value=''>---------Waiting List---------</option>";
            for (const key in res.stateWait) {
                if (Object.hasOwnProperty.call(res.stateWait, key)) {
                    const state = res.stateWait[key];
                    element.innerHTML += `<option value="${key}">${state}</option>`
                }
            }
        }

        if (res.disabled) {
            for (const key in res.disabled) {
                if (Object.hasOwnProperty.call(res.disabled, key)) {
                    const state = res.disabled[key];
                    element.innerHTML += `<option disabled value="${key}">${state}</option>`
                }
            }
        }
        if (id == 'ReferringState') {
            setCounties('FL', 'ReferringCounty');
        } else {
            setCounties('FL');
        }
    })
}

function setMsa(res, id) {
    const element = document.getElementById(id);
    if (!element) return;

    element.innerHTML = '';
    element.innerHTML += "<option value=''>Select Msa</option>";
    if (res.msa) {
        for (const key in res.msa) {
            if (Object.hasOwnProperty.call(res.msa, key)) {
                const msa = res.msa[key];
                element.innerHTML += `<option value="${msa}">${msa}</option>`
            }
        }
    }

    if (res.msaWait) {
        element.innerHTML += "<option disabled value=''>---------Waiting List---------</option>";
        for (const key in res.msaWait) {
            if (Object.hasOwnProperty.call(res.msaWait, key)) {
                const msaWait = res.msaWait[key];
                element.innerHTML += `<option value="${msaWait}">${msaWait}</option>`
            }
        }
    }
}

function setCounties(state_abb, countyId = 'ReferringState') {
    if (typeof state_abb === 'object') {
        state_abb = state_abb.currentTarget.value;
    }
    getCounties('', state_abb).then((res => {
        const element = document.getElementById('ReferringCounty');
        if (!element) return;
        element.innerHTML = '';
        if (Array.isArray(res.counties)) {
            res.counties.forEach(county => {
                element.innerHTML += `<option value="${county}">${county}</option>`
            })
            if (state_abb === 'FL') {
                element.value = 'Miami-Dade';
            }
        }
    }));

    // msa
    getMsas(state_abb, 'abb').then((res) => {
        setMsa(res, 'ReferringMsa');
    });
}

function setZipcode(msa, countyId = 'ReferringZipCode') {
    let state = document.getElementById('ReferringState').value;
    if (typeof msa === 'object') {
        msa = msa.currentTarget.value;
    }
    getZipByMsa(state, 'abb', msa).then((res => {
        const element = document.getElementById(countyId);
        if (!element) return;
        element.value = res.zipcode;
    }));
}

function mergeFormData(FD, form, inOneKeyWithJsonStrinfy = '') {
    const DATA = {};
    if (!document.getElementById(form)) { return FD; }
    document.getElementById(form)
        .querySelectorAll('input , select, textarea')
        .forEach(element => {
            if (inOneKeyWithJsonStrinfy) {
                DATA[element.id] = element.value;
            } else {
                FD.append(element.id, element.value);
            }
        })
    if (inOneKeyWithJsonStrinfy) {
        FD.append(inOneKeyWithJsonStrinfy, JSON.stringify(DATA));
    }
    return FD;
}

function mergeInnerFormsData(FD, form, inOneKeyWithJsonStrinfy = '') {
    let DATA = [];
    if (!document.getElementById(form)) { return FD; }
    document.getElementById(form)
        .querySelectorAll('form')
        .forEach((f) => {
            const innerFormData = {};
            f.querySelectorAll('input , select, textarea')
                .forEach(element => {
                    if (inOneKeyWithJsonStrinfy) {
                        innerFormData[element.id] = element.value;
                    } else {
                        FD.append(element.id, element.value);
                    }
                })
            DATA.push(innerFormData);
        })
    DATA = arrayChunk(DATA, 2);
    if (inOneKeyWithJsonStrinfy) {
        FD.append(inOneKeyWithJsonStrinfy, JSON.stringify(DATA));
    }
    return FD;
}

function arrayChunk(array, chunkSize) {
    if (!Array.isArray(array) || array.length === 0 || !Number.isInteger(chunkSize) || chunkSize <= 0) {
        return array;
    }

    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }

    return result;
}

function makeBtnProssess(btn, done = false) {
    if (done) {
        btn.disabled = false;
    } else {
        btn.disabled = true;
    }
}